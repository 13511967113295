import React from 'react'
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const PageTitleSection = styled.section`
  position: relative;
  min-height: 317px;
  margin-top: -100px;
  margin-bottom: 50px;
  background-color: var(--black);

  .page-title-bg {
    position: relative;
    min-height: 400px;

    @media(min-width: 480px) {
      min-height: 317px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      z-index: 1;
    }

    &:before {
      background: linear-gradient(to bottom, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
      top: 0;
    }

    &::after {
      background: linear-gradient(to top, var(--black), rgba(0 0 0 / 0%) 50%, rgba(0 0 0 / 0%));
      bottom: 0;
    }
  }

  .container {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    z-index: 2;

    @media(min-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .page-title-container {
      padding-left: 20px;
      padding-right: 20px;

      @media(min-width: 1200px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    h1,
    p {
      margin: 0 auto 35px;
      text-align: center;
    }

    h1 {
      max-width: 950px;
      text-shadow: 0 1px 6px rgba(0 0 0 / 50%);
    }

    p {
      max-width: 690px;
      color: var(--gray);
      font-size: 21px;
    }
  }
`

const PageTitle = ({title, paragraph}) => {

  return (
    <PageTitleSection>
      <StaticImage
        src="../images/page-title-bg.png"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
        objectFit="cover"
        alt=""
        className="page-title-bg"
      />
      <div className="container">
        <div className="page-title-container">
          <h1>{title}</h1>
          <p>{paragraph}</p>
        </div>
      </div>
    </PageTitleSection>
  );
}

export default PageTitle

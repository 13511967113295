import React from 'react'
import styled from "styled-components"
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const ServicesSection = styled.section`
  margin-bottom: 100px;

  .container {
    max-width: 800px;
    margin-bottom: 100px;

    .services-grid {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 45px;
      margin-bottom: 90px;

      @media(min-width: 640px) {
        flex-direction: row;
        align-items: center;
      }

      &:nth-child(even) {
        .services-graphic {
          order: 0;

          @media(min-width: 640px) {
            order: 1;
          }
        }
      }

      .services-graphic {
        position: relative;
      }

      .gatsby-image-wrapper {
        width: 270px;
        height: 270px;
      }

      .services-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
        width: 220px;

        p {
          margin-bottom: 0;
          font-size: 22px;
          text-transform: uppercase;
        }
      }

      .services-content {
        p {
          margin-bottom: 35px;
          color: var(--gray);
          font-size: 18px;
        }
      }
    }

    .services-btn-container {
      text-align: center;
    }
  }
`

const ServicesPage = (props) => {

  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.servicesPageTitle} paragraph={node.servicesPageTitleParagraph.servicesPageTitleParagraph} />
      ))}
      <ServicesSection>
        {props.data.Services.edges.map(({ node }) => (
          <div className="container" key={node.id}>
            {node.serviceOrder && node.serviceOrder.map((item) => (
              <div className="services-grid" key={item.id}>
                <div className="services-graphic">
                  <StaticImage
                    src="../images/home-services-frame-graphic.png"
                    width={270}
                    height={270}
                    alt=""
                  />
                  <div className="services-title">
                    <p>{item.title}</p>
                  </div>
                </div>
                <div className="services-content">
                  <h2>{item.title}</h2>
                  <p className="short-desc">{item.shortDescription.shortDescription}</p>
                  {/* <Link to={`/services/${item.slug}`} className="btn">Service Details</Link> */}
                  <a href={`/services/${item.slug}`} className="btn">Service Details</a>
                </div>
              </div>
            ))}
            <div className="services-btn-container">
              {/* <Link to="/contact/" className="btn">Contact Our Team Today!</Link> */}
              <a href="/contact/" className="btn">Contact Our Team Today!</a>
            </div>
          </div>
        ))}
      </ServicesSection>
      <NewsletterCTA />
    </main>
  )
}

export default ServicesPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const servicesContent = graphql`
  {
    pageTitleData: allContentfulServicesPage {
      edges {
        node {
          id
          servicesPageTitle
          servicesPageTitleParagraph {
            servicesPageTitleParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    Services: allContentfulServiceOrder {
      edges {
        node {
          id
          serviceOrder {
            id
            title
            slug
            shortDescription {
              shortDescription
            }
          }
        }
      }
    }
  }
`;